<template>
  <v-container v-if="$can(['alta_cliente'])" id="crud" fluid tag="section">
    <v-row dense>
      <v-col md="12">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col xs="12" md="6">
              <v-toolbar dark color="primary" dense>
                <v-toolbar-title> Datos Personales </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row class="flex justify-content-between">
                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="editedItem.tipo_documento"
                        :items="tipos"
                        item-value="id"
                        item-text="name"
                        label="Tipo de Documento"
                        :rules="[$rulesRequerido]"
                        return-object
                        @change="
                          onSelectIdentifierType(editedItem.tipo_documento)
                        "
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.documento"
                        :label="tipoLabel"
                        autofocus
                        ref="documentoTextField"
                        :placeholder="documentPlaceholder"
                        :rules="documentRules"
                        :maxLength="documentMaxLength"
                        :disabled="DocumentoDisabled"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="flex justify-content-between">
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Apellido"
                        autofocus
                        :rules="[$rulesRequerido, $rulesAlfaMax40]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Nombre"
                        autofocus
                        :rules="[$rulesRequerido, $rulesAlfaMax40]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-col>
            <v-col xs="12" md="6">
              <v-toolbar dark color="primary" dense>
                <v-toolbar-title> Datos de Contacto </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row class="flex justify-content-between" dense>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.phone"
                        label="Teléfono"
                        autofocus
                        :rules="[$rulesNumericoMax15]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.celular"
                        label="Celular"
                        autofocus
                        :rules="[$rulesTelefono]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="flex justify-content-between" dense>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Correo"
                        autofocus
                        :rules="[$rulesMail]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-col>
            <v-col md="12">
              <v-toolbar dark color="primary" dense>
                <v-toolbar-title> Datos de Dirección </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row class="flex justify-content-between" dense>
                    <v-col cols="12" md="3" sm="12">
                      <v-autocomplete
                        v-model="editedItem.pais"
                        :items="paises"
                        item-value="id"
                        item-text="name"
                        label="País"
                        autofocus
                        :rules="[$rulesRequerido]"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                      <template v-if="isArgentina">
                        <v-autocomplete
                          v-model="editedItem.provincia"
                          :items="provincias"
                          item-value="id"
                          item-text="nombre"
                          label="Provincia"
                          autofocus
                          :rules="[$rulesRequerido]"
                          return-object
                          @change="onSelectProvincia"
                        ></v-autocomplete>
                      </template>
                      <template v-else>
                        <v-text-field
                          v-model="editedItem.provincia"
                          label="Provincia"
                          autofocus
                          :rules="[$rulesRequerido]"
                        >
                        </v-text-field>
                      </template>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                      <template v-if="isArgentina">
                        <v-autocomplete
                          v-model="editedItem.departamento"
                          :items="departamentos"
                          item-value="id"
                          item-text="nombre"
                          label="Departamento"
                          autofocus
                          :rules="[$rulesRequerido]"
                          :disabled="!editedItem.provincia"
                          return-object
                        ></v-autocomplete>
                      </template>
                      <template v-else>
                        <v-text-field
                          v-model="editedItem.departamento"
                          label="Departamento"
                          autofocus
                          :rules="[$rulesRequerido, $rulesAlfaMax40]"
                          :disabled="!disabledLocation"
                        ></v-text-field>
                      </template>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                      <template v-if="isArgentina">
                        <v-autocomplete
                          v-model="editedItem.localidad"
                          :items="localidades"
                          item-value="id"
                          item-text="nombre"
                          label="Localidad"
                          autofocus
                          :rules="[$rulesRequerido]"
                          :disabled="!editedItem.departamento"
                        ></v-autocomplete>
                      </template>
                      <template v-else>
                        <v-text-field
                          v-model="editedItem.localidad"
                          label="Localidad"
                          autofocus
                          :rules="[$rulesRequerido, $rulesAlfaMax40]"
                          :disabled="!disabledLocation"
                        ></v-text-field>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="flex justify-content-between">
                    <v-col cols="12" md="3" sm="12">
                      <v-text-field
                        v-model="editedItem.calle"
                        label="Calle"
                        autofocus
                        :rules="[$rulesRequerido, $rulesAlfaNumMax50]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                      <v-text-field
                        v-model="editedItem.numero"
                        label="Número"
                        autofocus
                        :rules="[$rulesRequerido, $rulesNumericoMax6]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                      <v-text-field
                        v-model="editedItem.piso"
                        label="Piso"
                        autofocus
                        :rules="[$rulesAlfaNumMax6]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                      <v-text-field
                        v-model="editedItem.depto"
                        label="Depto"
                        autofocus
                        :rules="[$rulesAlfaNumMax6]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                      <v-text-field
                        v-model="editedItem.cp"
                        label="C.P."
                        autofocus
                        :rules="[$rulesRequerido, $rulesNumericoMax10]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col xs="12">
              <BaseButton
                color="btnSecondary"
                variant="outlined"
                class="mr-4"
                @click="$goBack()"
              >
                Cancelar
              </BaseButton>
              <BaseButton 
                color="btnSecondary"
                @click="cleanForm"
              >
                Limpiar
              </BaseButton>
            </v-col>
            <v-col class="text-right">
              <BaseButton
                color="btnPrimary"
                @click="showConfirmationModal()"
              >
                Confirmar
              </BaseButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";
import { BaseButton } from "../../components/design-system";

export default {
  name: "ClienteAlta",

  components: {
    BaseButton,
  },

  data() {
    return {
      titlePerms: "clientes",
      editedItem: {
        id: "",
        tipo_documento: null,
        documento: "",
        nombre: "",
        businessName: "",
        lastName: "",
        name: "",
        phone: "",
        celular: "",
        email: "",
        pais: "Argentina",
        provincia: "",
        departamento: "",
        localidad: "",
        localidad_id: "",
        cp: "",
        calle: "",
        numero: "",
        piso: "",
        depto: "",
        addressTypeId: "",
      },
      tipoLabel: "N° Documento",
      documentPlaceholder: null,
      documentRules: [() => true],
      documentMaxLength: null,
      DocumentoDisabled: false,
      identifiertypes: [],
      tipos: [],
      paises: [],
      provincias: [],
      departamentos: [],
      localidades: [],
      localidadesData: [],
      valid: null,
      isArgentina: null,
      disabledLocation: true,
      formPerson: { identifiers: [], addresses: [] },
      route: "clientes",
      routeLocalidades: "localidades",
      routeIdentifiertypes: "identifiertypes",
    };
  },

  computed: {
    ...mapState("anmac", ["clienteAnmac"]),
    ...mapState("aduana", ["clienteLocal"]),
  },

  mounted() {
    this.valid = null;
    this.fetchLocalidades();
    this.fetchIdentifierTypes();
  },

  methods: {
    ...mapMutations("anmac", {
      addClienteAnmac: "addCliente",
    }),
    ...mapMutations("aduana", {
      addClienteAduana: "addCliente",
    }),

    setDatosPrueba() {
      this.editedItem.tipo_documento = { id: 4, name: "DNI" };
      this.editedItem.documento = "27777777";
      this.editedItem.lastName = "Perez";
      this.editedItem.name = "Juan";
      this.editedItem.phone = "1111111111";
      this.editedItem.celular = "1556443322";
      this.editedItem.email = "juanPerez@mail.com";
      this.editedItem.pais = { code: "ARG", name: "ARGENTINA" };
      this.editedItem.provincia = "Buenos Aires";
      this.editedItem.departamento = "Mar Chiquita";
      this.editedItem.localidad = {
        id: "06518060002",
        name: "Camet Norte",
      };
      this.editedItem.cp = "1803";
      this.editedItem.calle = "ALEM";
      this.editedItem.numero = "100";
      this.editedItem.piso = "10";
      this.editedItem.depto = "C";
    },

    cleanForm() {
      //chequear funcion
      this.$refs.form.reset();
      const argentina = this.paises.find((pais) => pais.name === "ARGENTINA");
      this.editedItem.pais = argentina;
      this.valid = true;
    },
    confirmSave() {
      //No se esta usando
      //console.log('guardado '+this.$previousRoute);
      switch (this.$previousRoute.path) {
        case "/estampillas_ANMAC_venta":
          this.addClienteAnmac(this.editedItem);
          break;
        case "/elementos_aduana_venta":
          this.addClienteAduana(this.editedItem);
          break;
        default:
          console.log("Ruta anterior desconocida");
          break;
      }
      this.$router.push(this.$previousRoute);
      //this.$router.go(-1);
    },
    resetProvincia() {
      this.editedItem.provincia = null;
      this.editedItem.departamento = null;
      this.editedItem.localidad = null;
    },

    async fetchIdentifierTypes() {
      try {
        // Se comenta porque se obtienen los datos de $getData
        //const response = await this.$axiosApi.get(this.routeIdentifiertypes);
        //const filteredObjects = response.data.data.filter(
        //console.log("TIPOS: " + JSON.stringify(this.identifiertypes));
        const filteredObjects = this.identifiertypes.filter(
          (obj) => obj.isForJuridicalPerson === false && obj.name !== "CUIL"
        );
        this.tipos = filteredObjects.map((obj) => ({
          id: obj.id,
          name: obj.name,
          description: obj.description,
          identifierMask: obj.identifierMask,
          validationRegex: obj.validationRegex,
        }));
      } catch (error) {}
    },
    async getListPaisesForSelect() {
      try {
        //console.log("PAISES: " + JSON.stringify(this.paises));
        const argentina = this.paises.find((pais) => pais.name === "ARGENTINA");
        if (argentina) {
          this.editedItem.pais = argentina;
        }
      } catch (error) {
        console.error("Error al obtener la lista de países:", error);
      }
    },

    /*
    resetLocalidad(){
      this.editedItem.city = null;
    },*/
    /*cancelSave() {
      this.showConfirmationModal = false;
    },*/
    /*
    updateProvincias() {
      this.provincias = this.provincias[this.editedItem.pais];
    },*/

    showConfirmationModal() {
      if (this.$refs.form.validate()) {
        this.editedItem.name = this.capitalizeText(this.editedItem.name);
        this.editedItem.lastName = this.capitalizeText(
          this.editedItem.lastName
        );
        this.businessName =
          this.editedItem.name + " " + this.editedItem.lastName;

        const localidadData = this.localidades.map((loc) => ({
          id: loc.id,
          nombre: loc.nombre,
        }));
        let localidadSel = localidadData.find(
          (loc) => loc.id === this.editedItem.localidad
        );
        //console.log("LOCALIDAD: "+localidadSel);
        if (localidadSel) {
          localidadSel.nombre = this.capitalizeText(localidadSel.nombre);
        } else {
          localidadSel = {
            nombre: this.capitalizeText(this.editedItem.localidad),
            id: "00000000000",
          };
        }
        if (typeof this.editedItem.departamento.nombre === "undefined") {
          this.editedItem.departamento = {
            nombre: this.capitalizeText(this.editedItem.departamento),
          };
        }
        if (typeof this.editedItem.provincia.nombre === "undefined") {
          this.editedItem.provincia = {
            nombre: this.capitalizeText(this.editedItem.provincia),
          };
        }
        //console.log("DEPARTAMENTO: "+this.editedItem.departamento.nombre);
        //console.log("PROVINCIA: "+this.editedItem.provincia.nombre);
        //console.log("DEPARTAMENTO--: "+this.editedItem.departamento);
        //console.log("PROVINCIA--: "+this.editedItem.provincia);

        this.formPerson.identifiers = [];
        this.formPerson.emails = [];
        this.formPerson.phones = [];
        this.formPerson.addresses = [];

        //Datos Personales
        this.formPerson.firstName = this.editedItem.name
          ? this.editedItem.name
          : "";
        this.formPerson.lastName = this.editedItem.lastName
          ? this.editedItem.lastName
          : "";
        this.formPerson.businessName = this.businessName;

        this.formPerson.identifiers.push({
          identifierTypeId: this.editedItem.tipo_documento.id,
          identifier: this.editedItem.documento,
        });
        //Datos de contacto
        if (this.formPerson.emails) {
          this.formPerson.emails.push({
            email: this.editedItem.email,
          });
        }
        if (this.formPerson.phones) {
          if (this.formPerson.phones) {
            this.formPerson.phones.push({
              phoneNumber: this.editedItem.phone,
            });
          }
          if (this.formPerson.phones) {
            this.formPerson.phones.push({
              phoneNumber: this.editedItem.celular,
            });
          }
        }
        //Datos de dirección
        this.formPerson.addresses.push({
          address: {
            street: this.editedItem.calle ? this.editedItem.calle : "",
            number: this.editedItem.numero
              ? this.editedItem.numero.toString()
              : "",
            level: this.editedItem.piso ? this.editedItem.piso.toString() : "",
            unit: this.editedItem.depto ? this.editedItem.depto.toString() : "",
            postCode: this.editedItem.cp ? this.editedItem.cp.toString() : "",
            location: localidadSel.nombre ? localidadSel.nombre.toString() : "",
            localidad_id: localidadSel.id ? localidadSel.id.toString() : "",
            partido: this.editedItem.departamento.nombre
              ? this.editedItem.departamento.nombre.toString()
              : "",
            province: this.editedItem.provincia.nombre
              ? this.editedItem.provincia.nombre.toString()
              : "",
            country: this.editedItem.pais.name
              ? this.editedItem.pais.name.toString().toString()
              : "",
          },
          addressTypeId: 1,
        });
        //console.log(JSON.stringify(this.formPerson));
        Swal.fire({
          title: `Está por agregar un nuevo usuario a su listado. ¿Está seguro?`,
          text: ``,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            //this.confirmSave(); //por el momento datos de sessión
            //console.log("route: "+this.route);
            //console.log("person: "+this.formPerson);
            Swal.alertGetInfo("Guardando Datos del cliente");
            this.$axiosApi
              .postExternal(this.route, this.formPerson)
              .then((r) => {
                if (
                  typeof r !== "undefined" &&
                  r !== null &&
                  (r.status === 200 || r.status === 201 || r.succeeded === true)
                ) {
                  Swal.fire({
                    title: "Los datos se guardaron correctamente ",
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                  }).then(() => {
                    if (
                      this.$previousRoute &&
                      this.$previousRoute.path !== "/"
                    ) {
                      this.$router.push(this.$previousRoute);
                    } else {
                      this.$router.push("/portal");
                    }
                  });
                } else {
                  this.controlError({
                    response: {
                      data: {
                        message:
                          "Hubo un error al guardar los datos. Por favor, inténtalo de nuevo.",
                      },
                    },
                  });
                }
              })
              .catch((error) => {
                this.controlError(error);
              });
          }
        });
      }
    },

    controlError(error) {
      Swal.close();
      let errorMessage =
        "Hubo un error al guardar los datos. Por favor, inténtalo de nuevo.";
      let errorCode = "";

      try {
        if (error.response) {
          const responseData = error.response.data;
          const statusCode = error.response.status;

          if (responseData && responseData.message) {
            errorMessage =
              statusCode === 400
                ? "El número de documento ya existe. Por favor, inténtalo de nuevo"
                : responseData.message;
            errorCode = responseData.code;
          } else {
            errorMessage = error.response.statusText;
            errorCode = responseData.code;
          }
        } else if (error.request) {
          errorMessage = "No se recibió respuesta del servidor";
          errorCode = error.request;
        }
      } catch (error) {
        console.error("Error al procesar la respuesta:", error);
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
      });

      console.error("Error:", error);
      this.snackbar = true;
      this.text = "Error al ingresar los datos. Código: " + errorCode;
      this.color = "error";
    },

    onSelectIdentifierType() {
      this.$refs.documentoTextField.reset();
      const tipoDocumentoSelection = this.editedItem.tipo_documento
        ? this.editedItem.tipo_documento.name
        : null;
      const tipoDocumentoDesc = this.editedItem.tipo_documento.description;

      const formatGuide = this.editedItem.tipo_documento.identifierMask;
      if (formatGuide) {
        this.documentPlaceholder = formatGuide.replace(/#/g, "9");
        this.documentMaxLength = formatGuide.length;
      } else {
        this.documentPlaceholder = null;
        this.documentMaxLength = null;
      }

      const regexPattern = this.editedItem.tipo_documento.validationRegex;
      if (regexPattern) {
        this.documentRules = [
          (value) => {
            if (!value) return `El campo ${tipoDocumentoDesc} es requerido.`;

            if (new RegExp(regexPattern).test(value)) {
              return true;
            } else {
              return `Ingrese ${tipoDocumentoDesc} con el formato: ${this.documentPlaceholder}`;
            }
          },
        ];
      } else {
        this.documentRules = [() => true];
      }

      if (tipoDocumentoSelection === "PASAPORTE") {
        this.tipoLabel = "N° Pasaporte";
        this.DocumentoDisabled = null;
      } else if (tipoDocumentoSelection === "NO CONSTA") {
        this.tipoLabel = "N° Documento";
        this.DocumentoDisabled = "disabled";
      } else {
        this.tipoLabel = "N° Documento";
        this.DocumentoDisabled = null;
      }
    },

    async fetchLocalidades() {
      try {
        const response = await this.$axiosApi.get(this.routeLocalidades);
        const localidadesApi = response.data.data;

        const provinciasMap = {};
        const departamentosMap = {};
        localidadesApi.forEach((item) => {
          const provincia = {
            id: item.provincia.id,
            nombre: item.provincia.nombre,
          };
          const departamento = {
            id: item.partido.id,
            nombre: item.partido.nombre,
            provincia_id: item.provincia.id,
          };
          provinciasMap[provincia.id] = provincia;
          departamentosMap[departamento.id] = departamento;
        });
        const provincias = Object.values(provinciasMap);
        provincias.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));

        const departamentos_ = Object.values(departamentosMap);

        this.provincias = provincias;
        this.departamentosOrig = [...departamentos_];
        this.departamentos = [...departamentos_];
        this.localidadesOrig = [...localidadesApi];
        this.localidades = [...localidadesApi];
      } catch (error) {
        console.error("Error localidades: " + error);
      }
    },
    updateDepartamentos(provinciaId) {
      if (provinciaId !== undefined) {
        this.departamentos = this.departamentosOrig.filter((depto) => {
          return this.localidadesOrig.some(
            (loc) =>
              loc.provincia.id === provinciaId && loc.partido.id === depto.id
          );
        });
      }
    },
    resetSelects() {
      this.editedItem.departamento = "";
      this.editedItem.localidad = "";
    },
    onSelectProvincia(provinciaSeleccionada) {
      const provinciaId = provinciaSeleccionada.id;
      this.resetSelects();
      this.updateDepartamentos(provinciaId);
    },
    capitalizeText(text) {
      return text.toLowerCase().replace(/(^|\s)\S/g, function (char) {
        return char.toUpperCase();
      });
    },
  },

  watch: {
    "editedItem.pais": function (newVal) {
      this.isArgentina = newVal.name === "ARGENTINA";
      this.resetProvincia();
    },

    "editedItem.provincia"(newProvincia) {
      if (newProvincia !== null) {
        this.editedItem.departamento = "";
        this.updateDepartamentos(newProvincia.id);
      }
    },

    "editedItem.departamento"(newDepartamento) {
      if (newDepartamento !== null) {
        this.editedItem.localidad = "";
        this.updateDepartamentos(newDepartamento.provincia_id);
        if (newDepartamento) {
          /*
              this.localidades = this.localidadesOrig.filter(loc => {
                  return loc.provincia.nombre === this.editedItem.provincia.nombre && loc.departamento.nombre === newDepartamento.nombre;
              }).map(loc => loc.nombre);*/
          this.localidades = this.localidadesOrig.filter((loc) => {
            return (
              loc.provincia.nombre === this.editedItem.provincia.nombre &&
              loc.partido.nombre === newDepartamento.nombre
            );
          });
        }
      }
    },
  },

  async created() {
    try {
      //this.setDatosPrueba();
      const data = await this.$getData([
        "identifiertypes",
        "paises",
        "localidades",
      ]);
      this.identifiertypes = data.identifiertypes;
      this.paises = data.paises;
      this.localidadesData = data.localidades;
    } catch (error) {
      console.error("Error al obtener algun dato de getData:", error);
    }

    /* Se comenta porque se usa $getData 
    this.$getListPaisesForSelect("paises").then(() => {
      const argentina = this.paises.find((pais) => pais.name === "ARGENTINA");
      this.editedItem.pais = argentina;
    });
    */

    this.fetchLocalidades();
    this.fetchIdentifierTypes();
    this.getListPaisesForSelect();
  },

  // created() {},
};
</script>
<style>
.v-dialog__content {
  text-align: center;
}
.v-card__title,
.v-card__text,
.v-btn {
  color: black;
}
</style>
